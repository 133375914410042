import React from "react";
import { Layout, Container } from "~src/components";
import { Box } from "@rebass/grid/emotion";
import css from "@emotion/css";
import { colors, mq } from "~src/style";
import { Link } from "gatsby";

export default () => (
  <Layout>
    <Container>
      <Box
        css={css`
          max-width: 420px;
          margin: 16px;
          a {
            color: ${colors.white};
          }
          ${mq("lg")} {
            margin: 32px auto;
          }
        `}
      >
        <h1>Colofon</h1>
        <p>
          De interactieve kaart ‘Had ik toen maar een huis gekocht’ is gemaakt
          door Vers Beton en maakt deel uit van{" "}
          <a
            href="https://versbeton.nl/harde-kern/wie-bezit-onze-stad/"
            target="_blank"
          >
            onderzoeksdossier ‘Wie bezit onze stad?’
          </a>
          . In dit dossier geven we antwoord op de vraag wat de opkomst van
          particuliere verhuurders betekent voor Rotterdam.
        </p>
        <p>
          Via de kaart kun je ontdekken hoe de woningmarkt zich in de periode
          2008-2019 heeft ontwikkeld. Zo zie je hoe sterk de huizenprijzen zijn
          gestegen. Waar woningcorporaties hun bezit hebben afgestoten, terwijl
          beleggers juist veel woningen hebben opgekocht. En waar de WOZ-waardes
          de pan uit rijzen.
        </p>
        <p>
          Heb je vragen? Loop je vast? Ga naar Vers Beton voor{" "}
          <a
            href="https://versbeton.nl/2019/06/had-ik-toen-maar-een-huis-gekocht-bekijk-10-jaar-rotterdamse-woningmarkt"
            target="_blank"
          >
            meer uitleg en de FAQ
          </a>
          .
        </p>
        <p>
          Opdrachtgever:{" "}
          <a href="http://versbeton.nl" target="_blank">
            Vers Beton
          </a>
          <br />
          Onderzoek:{" "}
          <a href="http://meneergeluk.nl" target="_blank">
            Maurice Geluk
          </a>
          <br />
          Ontwerp en ontwikkeling:{" "}
          <a href="http://afdelingbuitengewonezaken.nl" target="_blank">
            Afdeling Buitengewone Zaken
          </a>
          <br />
          Illustraties:{" "}
          <a href="https://www.instagram.com/nina.fernande/" target="_blank">
            Nina Fernande
          </a>
          <br />
          Video:{" "}
          <a href="http://openrotterdam.nl" target="_blank">
            OPEN Rotterdam
          </a>
          <br />
          Projectleider:{" "}
          <a
            href="https://versbeton.nl/personen/hilde-westerink/"
            target="_blank"
          >
            Hilde Westerink
          </a>
        </p>
        <p>
          Met dank aan: Eeva Liukku, Margot Smolenaars, Willemijn Sneep,
          gemeente Rotterdam-OBI
        </p>
        <p>
          Dit project is mogelijk gemaakt dankzij bijdragen van Fonds Bijzondere
          Journalistieke Projecten, Stimuleringsfonds Creatieve Industrie en
          Stimuleringsfonds voor de Journalistiek.
        </p>
        <p>
          <Link
            css={css`
              background: ${colors.white};
              padding: 16px 32px;
              color: ${colors.blue2} !important;
              font-weight: bold;
              text-decoration: none;
              margin: 32px 0;
              display: inline-block;
            `}
            to={`/app/`}
          >
            Terug naar de kaart
          </Link>
        </p>
      </Box>
    </Container>
  </Layout>
);
